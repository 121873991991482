<template>
    <v-card class="pa-0 mt-0">
        <v-toolbar dense flat color="indigo" dark>
            <v-toolbar-title>
                Client tokens
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="white" @click="createToken"> <!-- TODO: was: createTokenDialog = true  but currently we don't have any options in that dialog like duration, etc. so we skip it and immediately create the token -->
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
            </v-btn>
        </v-toolbar>
        <v-card-text v-if="Array.isArray(clientTokenList)">
            <p class="mb-0" v-if="clientTokenList.length === 0">No tokens.</p>
            <v-simple-table dense v-if="clientTokenList.length > 0">
                <template #default>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Created</th>
                            <th>Expires</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="token in clientTokenList" :key="token.id">
                            <td>{{ token.id }}</td>
                            <td>{{ formatDate(token.created_on) }}</td>
                            <td>
                                <template v-if="token.not_after">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">
                                                <!-- <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="orange--text"/> -->
                                                {{ formatDate(token.not_after) }}
                                            </span>
                                        </template>
                                        <span>{{ formatDateLong(token.not_after) }}</span>
                                    </v-tooltip>
                                </template>
                            </td>
                            <td class="text-end">
                                <v-btn icon color="red" @click="deleteToken(token.id)">
                                    <font-awesome-icon :icon="['fas', 'trash']"></font-awesome-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-dialog v-model="createTokenDialog" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="indigo lighten-3">
                <v-toolbar-title>Create client token</v-toolbar-title>
            </v-toolbar>
            <v-form @submit.prevent="createToken" @keyup.enter.native.prevent="createToken" class="px-2">
                <!-- <v-text-field v-model="newItemLabel" label="Label" hint="A label for this client token; for example which application or server is using it" ref="newItemLabelInput"></v-text-field> -->
            </v-form>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="indigo white--text" @click="createToken" :disabled="!isCreateTokenFormComplete">
                    <span>Create</span>
                </v-btn>
                <v-btn text color="grey" @click="createTokenDialog = false">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <v-dialog v-model="displayTokenDialog" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="indigo lighten-3">
                <v-toolbar-title>New client token</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p class="mt-4">Copy this token to your client application's configuration.</p>
                <p>This is the only time it will be shown.</p>
                <!-- <v-textarea v-model="displayToken" readonly outlined hide-details></v-textarea> -->

                <v-tabs v-model="displayTokenTab">
                    <v-tabs-slider color="indigo"></v-tabs-slider>
                    <v-tab>Token</v-tab>
                    <v-tab-item>
                        <v-card>
                        <!-- <p class="mb-0 pa-2" style="border: 1px solid black;">{{ token }}</p> -->
                        <v-textarea v-model="displayToken" readonly outlined hide-details></v-textarea>
                        </v-card>
                    </v-tab-item>
                    <v-tab>JSON</v-tab>
                    <v-tab-item>
                        <v-card>
                        <!-- <p class="mb-0 pa-2" style="border: 1px solid black;">{{ tokenFormatJson }}</p> -->
                        <v-textarea v-model="tokenFormatJson" readonly outlined hide-details></v-textarea>
                        </v-card>
                    </v-tab-item>
                    <v-tab>INI</v-tab>
                    <v-tab-item>
                        <v-card>
                        <!-- <p class="mb-0 pa-2" style="border: 1px solid black;">{{ tokenFormatIni }}</p> -->
                        <v-textarea v-model="tokenFormatIni" readonly outlined hide-details></v-textarea>
                        </v-card>
                    </v-tab-item>
                    <v-tab>cURL</v-tab>
                    <v-tab-item>
                        <v-card>
                        <!-- <p class="mb-0 pa-2" style="border: 1px solid black;">{{ tokenFormatCurl }}</p> -->
                        <v-textarea v-model="tokenFormatCurl" readonly outlined hide-details></v-textarea>
                        </v-card>
                    </v-tab-item>
                </v-tabs>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="indigo white--text" @click="displayTokenDialog = false">
                    <span>Close</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-card>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    props: ['enterprise', 'realm', 'client'],
    components: {
    },
    data: () => ({
        forbiddenError: false,
        clientTokenList: null,
        status: null,
        error: null,

        submitFormTimestamp: null,

        createTokenDialog: false,
        displayToken: null,
        displayTokenDialog: false,
        displayTokenTab: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            clientPermitOriginProtocolChoices: (state) => state.clientPermitOriginProtocolChoices,
            clientPublishedChoices: (state) => state.clientPublishedChoices,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryTextStyle: 'primaryTextStyle',
            primaryIconButtonStyle: 'primaryIconButtonStyle',
        }),
        isViewReady() {
            return this.enterprise !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
        isCreateTokenFormComplete() {
            return true;
        },
        // customerClientLink() {
        //     // TODO: get default site with possible custom hostname...  and the brandprofile, if needd...
        //     return "";http://customer.etherlink-main.test/brand/libertydns/client?id=06J5B62A4Z7WRCCBET4G
        // },
        yesnoChoices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        permitOriginProtocolList() {
            return this.client.permit?.origin_protocol ?? [];
        },
        endpointURL() {
            return process.env.VUE_APP_SERVICE_ENDPOINT_URL ?? 'https://admin.cryptium.com/api';
        },
        cryptiumIdEndpointURL() {
            return process.env.VUE_APP_CRYPTIUM_ID_API_URL ?? 'https://id.cryptium.app/api';
        },
        tokenFormatJson() {
            if (this.displayToken) {
                // return JSON.stringify({ domain: this.domain, host: this.host, token: this.token }, null, 2);
                return JSON.stringify({ url: this.endpointURL, token: this.displayToken }, null, 2);
            }
            return '';
        },
        tokenFormatIni() {
            if (this.displayToken) {
                // return `domain=${this.domain}\nhost=${this.host}\ntoken=${this.token}\n`;
                return `[cryptium_id]\nCRYPTIUM_ID_API_URL="${this.cryptiumIdEndpointURL}"\nCRYPTIUM_ID_API_TOKEN="${this.displayToken}"\n`;
            }
            return '';
        },
        tokenFormatCurl() {
            if (this.displayToken) {
                // const urltemplate = process.env.VUE_APP_CLIENT_DYNAMIC_DNS_URL_TEMPLATE ?? 'https://client.etherlinkapp.com/edit/dynamic?domain={{domain}}&host={{host}}';
                const urltext = this.endpointURL;
                // TODO: mustache replace domain and host in template, isntead of these replace function calls, so we have more flexiblity with whitespace and escaping
                // const urltext = urltemplate.replace('{{domain}}', this.domain).replace('{{host}}', this.host);
                const cmdtemplate = 'curl -X POST -H \'Authorization: Bearer {{token}}\' \'{{url}}\'';
                const cmdtext = cmdtemplate.replace('{{token}}', this.displayToken).replace('{{url}}', urltext);
                return cmdtext;
            }
            return '';
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadClientTokenList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadClientTokenList: true });
                const query = { client_id: this.$route.query.id };
                const response = await this.$client.cryptiumIdRealm(this.$route.params.enterpriseId, this.$route.params.realmId).realmClientToken.search(query);
                console.log(`editclient.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.clientTokenList = response.list;
                } else {
                    this.clientTokenList = [];
                }
            } catch (err) {
                console.error('failed to load tokens', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadClientTokenList: false });
            }
        },
        async createToken() {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientAttr: true });
                const response = await this.$client.cryptiumIdRealm(this.enterprise.id, this.realm.id).realmClientToken.create({
                    client_id: this.client.id,
                });
                console.log(`saveClientAttr: response ${JSON.stringify(response)}`);
                if (response?.status?.isCreated && response?.status?.token) {
                    if (response.status.item) {
                        this.clientTokenList.push(response.status.item);
                    }
                    this.createTokenDialog = false;
                    this.displayToken = response.status.token;
                    this.displayTokenDialog = true;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create client token' });
                }
            } catch (err) {
                console.error('failed to edit client token', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create client token' });
            } finally {
                this.$store.commit('loading', { saveClientAttr: false });
            }
        },
        async saveClientToken(item) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientToken: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).realmClientToken.edit({ id: item.id }, item);
                console.log(`saveClientToken: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    const editedItem = { ...item, is_draft_pending: true };
                    const idx = this.clientTokenList.findIndex((storedItem) => storedItem.id === item.id);
                    if (idx > -1) {
                        this.clientTokenList.splice(idx, 1, editedItem);
                    } else {
                        this.clientTokenList.push(editedItem);
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save client token' });
                }
            } catch (err) {
                console.error('failed to save client token', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to cresaveate client token' });
            } finally {
                this.$store.commit('loading', { saveClientToken: false });
            }
        },
        formatDate(timestamp) {
            const d = new Date(timestamp);
            return d.toDateString();
        },
        formatDateLong(timestamp) {
            const d = new Date(timestamp);
            return d.toString();
        },
        async deleteToken(id) {
            try {
                this.error = false;
                this.$store.commit('loading', { deleteToken: true });
                const response = await this.$client.cryptiumIdRealm(this.enterprise.id, this.realm.id).realmClientToken.delete({
                    id,
                    client_id: this.client.id,
                });
                console.log(`deleteToken: response ${JSON.stringify(response)}`);
                if (response?.status?.isDeleted) {
                    const idx = this.clientTokenList.findIndex((item) => item.id === id);
                    if (idx > -1) {
                        this.clientTokenList.splice(idx, 1);
                    } else {
                        this.loadClientTokenList();
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete client token' });
                }
            } catch (err) {
                console.error('failed to delete client token', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete client token' });
            } finally {
                this.$store.commit('loading', { deleteToken: false });
            }
        },
    },
    mounted() {
        this.loadClientTokenList();
    },
};
</script>

<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :enterpriseId="$route.params.enterpriseId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <v-row justify="center" class="py-5 px-10" v-if="client">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption text-end grey--text text--darken-2 mb-0">Client {{ client.id }}</p>
                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="indigo" dark>
                            <!-- TODO: the draft/published feature here from unicorn springs could be repurposed to an active/inactive, or revoke access feature, so user can revoke access for a client without deleting the record (so we can still know the client id in logs etc.) -->
                            <v-toolbar-title>
                                Client
                                <!-- <span v-if="client.is_draft_pending && !client.published">(draft)</span>
                                <span v-if="client.is_draft_pending && client.published">(published)</span> -->
                                <!-- <v-chip v-if="client.is_active" label small class="px-1 ml-2 indigo white--text">Active</v-chip>
                                <v-chip v-if="!client.is_active" label small class="px-1 ml-2 indigo black--text">Inactive</v-chip> -->
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <!-- <v-btn text small class="px-1 ml-2 indigo white--text" @click="publishClient" v-if="client.is_draft_pending && client.is_active">Update</v-btn>
                            <v-btn text small class="px-1 ml-2 indigo white--text" @click="publishClient" v-if="client.is_draft_pending && !client.is_active">Publish</v-btn>
                            <v-btn icon color="white" @click="publishClient" v-if="client.is_draft_pending">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" fixed-width/>
                            </v-btn> -->
                        </v-toolbar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-4">Name</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="client.label" @input="saveClientLabel" dense></EditableText>
                            </p>

                            <p class="text-overline mb-0 mt-4">Origin</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableText :value="client.info.origin" @input="saveClientOrigin" dense></EditableText> <!-- TODO: origin will be moving to client.origin -->
                            </p>

                            <p class="text-overline mb-0 mt-4">Allowed Protocols</p>
                            <p class="mb-0 pb-0 mt-0">
                                <EditableTextSelectMultiple :value="client.permit.origin_protocol" :items="[{value:'https',text:'HTTPS (secure)'},{value:'http',text:'HTTP (insecure)'}]" @input="saveClientPermitOriginProtocol" dense></EditableTextSelectMultiple>
                            </p>

                        </v-card-text>
                    </v-card>
                    <RealmClientTokenCard :enterprise="enterprise" :realm="realm" :client="client" class="mt-8"></RealmClientTokenCard>
                    <!-- <RealmAccessTokenCard :enterprise="enterprise" :realm="realm" :client="client" class="mt-8"></RealmAccessTokenCard> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
import EditableText from '@/components/EditableText.vue';
// import EditableTextSelect from '@/components/EditableTextSelect.vue';
import EditableTextSelectMultiple from '@/components/EditableTextSelectMultiple.vue';
// import EditableClientToken from '@/components/EditableClientToken.vue';
// import TextLink from '@/components/TextLink.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/cryptium_id/RealmBar.vue';
import RealmClientTokenCard from '@/components/realm-dashboard/RealmClientTokenCard.vue';
// import RealmAccessTokenCard from '@/components/realm-dashboard/RealmAccessTokenCard.vue';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
        // TextLink,
        EditableText,
        // EditableTextSelect,
        EditableTextSelectMultiple,
        // EditableClientToken,
        RealmClientTokenCard,
        // RealmAccessTokenCard,
    },
    data: () => ({
        realm: null,
        forbiddenError: false,
        enterprise: null,
        client: null,
        clientTokenList: null,
        status: null,
        error: null,

        dialogEditBrandProfile: false,
        editableBrandProfileAlias: null,
        submitFormTimestamp: null,

        addTokenDialog: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            clientPermitOriginProtocolChoices: (state) => state.clientPermitOriginProtocolChoices,
            clientPublishedChoices: (state) => state.clientPublishedChoices,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryTextStyle: 'primaryTextStyle',
            primaryIconButtonStyle: 'primaryIconButtonStyle',
        }),
        isViewReady() {
            return this.enterprise !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
        isCreateTokenFormComplete() {
            return false;
        },
        // customerClientLink() {
        //     // TODO: get default site with possible custom hostname...  and the brandprofile, if needd...
        //     return "";http://customer.etherlink-main.test/brand/libertydns/client?id=06J5B62A4Z7WRCCBET4G
        // },
        yesnoChoices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        permitOriginProtocolList() {
            return this.client.permit?.origin_protocol ?? [];
        },
    },
    watch: {
        dialogEditBrandProfile(newValue) {
            if (newValue) {
                this.editableBrandProfileAlias = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableBrandProfileInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadEnterprise() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEnterprise: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).self.get();
                console.log(`enterprise/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.enterprise = response;
                } else {
                    // TODO: redirect back to enterprise list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load enterprise', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEnterprise: false });
            }
        },
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.cryptiumId(this.$route.params.enterpriseId).realm.get({ id: this.$route.params.realmId });
                console.log(`realm/dashboard.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/dashboard.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadClient() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadClient: true });
                const query = { id: this.$route.query.id };
                const response = await this.$client.cryptiumIdRealm(this.$route.params.enterpriseId, this.$route.params.realmId).realmClient.get(query);
                console.log(`editclient.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.client = response;
                    this.loadClientTokenList();
                    // this.checkClient();
                } else {
                    // TODO: redirect back to enterprise list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load client', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadClient: false });
            }
        },
        // async checkClient() {
        //     try {
        //         this.error = false;
        //         this.$store.commit('loading', { checkClient: true });
        //         const query = { id: this.$route.query.id };
        //         const response = await this.$client.enterprise(this.$route.params.enterpriseId).client.check(query, { item: 'stripe' });
        //         console.log(`editclient.vue: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.status = response;
        //         } else {
        //             this.status = null;
        //         }
        //     } catch (err) {
        //         console.error('failed to check client status', err);
        //         this.status = null;
        //         this.error = true;
        //     } finally {
        //         this.$store.commit('loading', { checkClient: false });
        //     }
        // },
        async loadClientTokenList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadClientTokenList: true });
                const query = { client_id: this.$route.query.id };
                const response = await this.$client.cryptiumIdRealm(this.$route.params.enterpriseId, this.$route.params.realmId).realmClientToken.search(query);
                console.log(`editclient.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.clientTokenList = response.list;
                } else {
                    this.clientTokenList = [];
                }
            } catch (err) {
                console.error('failed to load tokens', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadClientTokenList: false });
            }
        },
        async saveClientAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientAttr: true });
                const response = await this.$client.cryptiumIdRealm(this.$route.params.enterpriseId, this.$route.params.realmId).realmClient.edit({ id: this.$route.query.id }, { [name]: value });
                console.log(`saveClientAttr: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.client, name, value);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit client' });
                }
            } catch (err) {
                console.error(`failed to edit client attr [${name}]: ${JSON.stringify(value)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit client' });
            } finally {
                this.$store.commit('loading', { saveClientAttr: false });
            }
        },
        async saveClientLabel(label) {
            await this.saveClientAttr('label', label);
        },
        // TODO: should the permitted origin protocols actually be a realm setting that applies to ALL clients?  because clients in development should be using a develompent realm, not the real one, and since it works the same when they're in production they can easily be configured for the production realm that only allows https
        async saveClientPermitOriginProtocol(list) {
            const permit = this.client.permit ?? {};
            permit.origin_protocol = list;
            await this.saveClientAttr('permit', permit);
        },
        async saveClientOrigin(origin) {
            const info = this.client.info ?? {};
            info.origin = origin;
            // await this.saveClientAttr('info', info);
            await this.saveClientAttr('origin', origin);
            this.$set(this.client, 'info', info);
        },
        async createToken() {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientAttr: true });
                const response = await this.$client.cryptiumId(this.$route.params.enterpriseId).clientToken.create({
                    client_id: this.$route.query.id,
                });
                console.log(`saveClientAttr: response ${JSON.stringify(response)}`);
                if (response?.isCreated && response.item) {
                    const editedItem = { ...response.item, is_active: false, is_draft_pending: true };
                    this.clientTokenList.push(editedItem);
                    this.$set(this.client, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create client token' });
                }
            } catch (err) {
                console.error('failed to edit client token', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create client token' });
            } finally {
                this.$store.commit('loading', { saveClientAttr: false });
            }
        },
        async saveClientToken(item) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveClientToken: true });
                const response = await this.$client.cryptiumId(this.$route.params.enterpriseId).clientToken.edit({ id: item.id }, item);
                console.log(`saveClientToken: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    const editedItem = { ...item, is_draft_pending: true };
                    const idx = this.clientTokenList.findIndex((storedItem) => storedItem.id === item.id);
                    if (idx > -1) {
                        this.clientTokenList.splice(idx, 1, editedItem);
                    } else {
                        this.clientTokenList.push(editedItem);
                    }
                    this.$set(this.client, 'is_draft_pending', true);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save client token' });
                }
            } catch (err) {
                console.error('failed to save client token', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to cresaveate client token' });
            } finally {
                this.$store.commit('loading', { saveClientToken: false });
            }
        },
    },
    mounted() {
        this.loadEnterprise();
        this.loadRealm();
        this.loadClient();
    },
};
</script>

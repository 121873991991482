<template>
    <v-form @submit.prevent="submit" @keyup.enter.prevent="submit">
        <template v-if="!editMode">
            <slot name="view">
                <span v-if="label">{{ label }}: </span>
                <v-chip v-for="(item, idx) in value" :key="idx" small class="mx-1">{{ displayText(item) }}</v-chip>
            </slot>
            <slot name="button">
                <v-btn icon color="indigo" @click="editMode = true">
                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                </v-btn>
            </slot>
        </template>
        <template v-if="editMode">
            <v-row no-gutters>
                <slot name="input">
                    <v-select v-model="editableSelection" :label="label" :items="items" :hint="hint" :dense="dense" multiple outlined small-chips ref="editableSelectionInput"></v-select>
                </slot>
                <slot name="submit">
                    <v-btn icon color="indigo" @click="submit">
                        <font-awesome-icon :icon="['fas', 'check']"/>
                    </v-btn>
                    <v-btn icon color="grey darken-2" @click="cancel">
                        <font-awesome-icon :icon="['fas', 'times']"/>
                    </v-btn>
                </slot>
            </v-row>
        </template>
    </v-form>
</template>

<script>
export default {
    props: ['value', 'dense', 'label', 'hint', 'items'],
    data: () => ({
        editMode: false,
        editableSelection: [],
        submitFormTimestamp: null,
    }),
    watch: {
        editMode(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.editableSelection = this.value;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableSelectionInput'); }, 1);
                });
            }
        },
    },
    methods: {
        displayText(value) {
            if (typeof value === 'undefined' || value === null) {
                return '';
            }
            return this.items.find((item) => item.value === value)?.text ?? value;
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        submit() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            this.$emit('input', this.editableSelection);
            this.cancel();
        },
        cancel() {
            this.editMode = false;
            this.editableSelection = [];
        },
    },
};
</script>
